<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:11:33
-->
<script>
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    unReadCount: {
      type: Number,
      default: 0,
    },
  },
  render(h, context) {
    const { icon, title, unReadCount } = context.props;
    const vnodes = [];
    if (unReadCount > 0) {
      vnodes.push(<span class="hasNew"></span>);
    }
    if (icon) {
      // vnodes.push(<svg-icon icon-class={icon}/>)
      vnodes.push(<img class="sliderBarIcon" src={icon} />);
    } else {
      vnodes.push(<span class="sliderBarIcon" />);
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>);
    }
    return vnodes;
  },
};
</script>
<style lang="scss">
.sliderBarIcon {
  width: 1em;
  height: 1em;
  margin-right: 14px;
  margin-left: 20px;
  border: none;
  display: inline-block;
}
.hasNew {
  width: 12px;
  height: 12px;
  background-color: #51cbf0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 24px;
  margin-top: -6px;
  line-height: 12px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}
</style>
