<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2022-01-18 16:21:38
-->
<template>
  <div>
    <div class="breadWrap">
      <hamburger
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      />
      <breadcrumb class="breadcrumb-container" />
    </div>
    <section class="app-main">
      <transition name="fade-transform" mode="out-in">
        <template v-if="$route.meta.keepAlive">
          <keep-alive>
            <router-view :key="key" />
          </keep-alive>
        </template>
        <template v-if="!$route.meta.keepAlive">
          <router-view :key="key" />
        </template>
      </transition>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
export default {
  name: "AppMain",
  components: {
    Breadcrumb,
    Hamburger,
  },
  computed: {
    key() {
      return this.$route.path;
    },
    ...mapGetters(["sidebar"]),
    keepLiveRoute() {
      return this.$store.state.keepAlive.keepLiveRoute;
    },
  },
  mounted() {},
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
  },
};
</script>

<style scoped lang="scss">
.app-main {
  /*68 = navbar  */
  height: calc(100vh - 118px);
  width: 100%;
  position: relative;
  overflow-y: auto;
  // padding-bottom: 40px;
  // -webkit-overflow-scrolling: touch;
  // padding-bottom:40px;
  // box-sizing: border-box;
}
.fixed-header + .app-main {
  padding-top: 118px;
}

.breadcrumb-container {
  float: left;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
.hamburger-container {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
}
.breadWrap {
  height: 50px;
  width: 100%;
  background-color: #dbe5eb;
}
</style>
