<template>
  <div class="navbar">
    <!-- <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />-->

    <div class="right-menu">
      <div class="rightItem rightItem1">
        <span style="font-weight: bold; font-size: 20px">
          {{ programInfo.title }}</span
        >
      </div>
      <div class="rightItem rightItem1" v-if="programInfo.telephone">
        <img src="../../assets/phone.png" />
        <span>客服电话 {{ programInfo.telephone }}</span>
      </div>
      <div class="rightItem" @click="changepass">
        <img src="../../assets/changepassword.png" />
        <span>修改密码</span>
      </div>
      <div class="rightItem" @click="logout">
        <img src="../../assets/logout.png" />
        <span>安全退出</span>
      </div>
    </div>
    <el-dialog
      :visible.sync="resetFlag"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="修改密码"
      append-to-body
      custom-class="passDialog"
      @close="cancel"
    >
      <el-form
        ref="newsForm"
        :model="passwordForm"
        :rules="passwordRules"
        @submit.native.prevent
      >
        <el-form-item
          :rules="[
            { required: true, trigger: 'blur', message: '请输入旧密码' },
          ]"
          class="elItem"
          prop="oldPassword"
        >
          <el-input
            v-model.trim="passwordForm.oldPassword"
            type="password"
            placeholder="请输入旧密码"
          />
        </el-form-item>
        <el-form-item class="elItem" prop="newPassword">
          <el-input
            v-model.trim="passwordForm.newPassword"
            type="password"
            placeholder="请输入新密码"
            class="h14"
            maxlength="12"
          />
        </el-form-item>
        <el-form-item class="elItem" prop="newPassword2">
          <el-input
            v-model.trim="passwordForm.newPassword2"
            type="password"
            placeholder="请再一次输入新密码"
            class="h14"
            maxlength="12"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="cancel">取消</el-button>
        <el-button type="primary" size="medium" @click="configConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Breadcrumb from '@/components/Breadcrumb'
// import Hamburger from '@/components/Hamburger'

import { changePassword } from "@/apis/user";
export default {
  // components: {
  //   Breadcrumb,
  //   Hamburger
  // },

  data() {
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
    var validateNewPwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error("密码须包含数字和字母，且密码位数为6-12位"));
      } else if (this.passwordForm.oldPassword === value) {
        callback(new Error("新密码与旧密码不可一致！"));
      } else {
        callback();
      }
    };
    var validateComfirmPwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error("密码须包含数字和字母，且密码位数为6-12位"));
      } else if (this.passwordForm.newPassword !== value) {
        callback(new Error("确认密码与新密码不一致！"));
      } else {
        callback();
      }
    };
    return {
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        newPassword2: "",
      },

      resetFlag: false,
      passwordRules: {
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validateNewPwd, trigger: "blur" },
        ],
        newPassword2: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validateComfirmPwd, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["sidebar", "programInfo"]),
  },

  methods: {
    // toggleSideBar() {
    //   this.$store.dispatch('app/toggleSideBar')
    // },
    async logout() {
      await this.$store.dispatch("logout");
      this.$store.dispatch("resetToken");
      this.$router.replace(`/login`);
      this.$store.commit("SET_PROGRAMINFO", "");
      this.$store.commit("permission/SET_ROUTES", []);
    },
    changepass() {
      this.resetFlag = true;
    },
    cancel() {
      this.resetFlag = false;

      this.$refs.newsForm.resetFields();
    },
    configConfirm() {
      this.$refs.newsForm.validate((valid) => {
        if (valid) {
          changePassword({
            password: this.passwordForm.oldPassword,
            newPassword: this.passwordForm.newPassword,
          }).then(() => {
            this.$message1("密码修改成功,请重新登录", "success");
            setTimeout(() => {
              this.logout();
            }, 800);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.passDialog {
  border-radius: 20px;
  width: 500px;
  .el-dialog__body {
    padding-top: 10px;
    // padding-bottom: 10px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .h14 {
    margin-top: 14px;
  }
}
</style>
<style lang="scss" scoped>
.navbar {
  height: 68px;
  overflow: hidden;
  position: relative;
  background: #3a3f4c;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding-right: 30px;

  // .hamburger-container {
  //   line-height: 64px;
  //   height: 100%;
  //   float: left;
  //   cursor: pointer;
  //   transition: background .3s;
  //   -webkit-tap-highlight-color:transparent;

  //   &:hover {
  //     background: rgba(0, 0, 0, .025)
  //   }
  // }

  // .breadcrumb-container {
  //   float: left;
  // }

  .right-menu {
    float: right;
    height: 100%;
    // line-height: 68px;
    display: flex;
    &:focus {
      outline: none;
    }
    .rightItem {
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-right: 42px;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
      }
    }
    .rightItem1 {
      // margin-right:64px;

      cursor: default;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
